* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: monospace, sans-serif;
  }
  
  html, body {
    font-size: 18px;
    background-color: #415050;   
    scroll-behavior: smooth; 
  }

  .titulos-principal {
    font-size: 50px;
    font-weight: 900;
    margin-top: 1em;    
    text-align: center;
    justify-content: center;
    color: white;
  }
  .titulos-principal-1 {
    font-size: 50px;
    font-weight: 900;
    margin-top: 2em;    
    text-align: center;
    justify-content: center;
    color: white;
  }


  .servicios {
    display: grid;
    grid-template-columns:repeat(3, 1fr);
    /* gap:10px;
    margin:10px; */
    
  }
  

  @media only screen and (max-width: 768px) {
    body {
      margin:0;
    }
    .titulos-principal  {
      margin-bottom: 0.5em;
    }
    .titulos-principal-1 {
      margin-bottom: 0.5em;
    }

    .servicios {
      /* display: grid;
      grid-template-columns:repeat(2, 1fr);
      padding-left:10px;
      width: 100vw; */

      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: repeat(2,1fr);
      gap: 10px;
      margin-left:6vw;
    }
    .footer {
      width: 100vw;
    }
    
  }
  

  
  