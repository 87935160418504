.footer-contenedor {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background-color: #314040;
    height: 50px;
    
    
}
.footer-contenedor a {
    text-decoration: none;
    color: black;
}
.footer-contenedor a:hover {
    color: white;
    
}
@media only screen and (max-width: 768px) { 
   
}


