
.navbar-contenedor{    
    background-color: #617171;
    display: flex;
    justify-content: space-between;    
    height: 4em;
    padding: 10px 10%;   
    position: fixed;
    top: 0;
    left: 0; 
    width: 100%;
    z-index: 1000;    
    align-items: center;
    
}

.logo-contenedor {
    max-width: 100%;
    overflow: hidden;
}


.logo {
    max-width: 100px;
    margin-top: 30px;
    
}


.navLinks {
    list-style: none;
    scroll-behavior: smooth;
    
}
.navLinks li {    
    padding: 1em;
    display: inline-block; 
    
}

.navLinks a {
    font-size: 20px;
    text-decoration: none;
    color: white;
    
}

.navLinks a:visited {
    text-decoration: none;
    color: #FAFAFA;
}

li:hover {
    background-color: #718181;
}

@media only screen and (max-width: 768px)  {
    .navLinks {
        display: none;
    }
    .logo {
        margin-left:10px;
        margin-right: 100px;

        
    }

    .logo img {
        width: 200px;
    }

    .navbar-contenedor {        
        justify-content: center;
        padding: 0;
        
    }

    
}