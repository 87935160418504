.servicios-contenedor {    
    margin: 30px 10px 10px 10px;
    display: flex;
    flex-direction: column;    
    align-items: center;    
    background-color: #C8C6C5;
    box-shadow: 0 3px 13px 1px rgb(0 0 0 / 9%);
    
}

.servicios-imagen {
    width: 100%;
    max-height: 100%;
    height: 100%;
    
}

.servicios-texto-contenedor {
    text-align: center;
    padding: 40px;
    font-family: monospace, sans-serif;
    font-weight: 400;
    line-height: 1.5rem;

}

.servicios-titulo {
    font-size: 1.3rem;
}

.servicios-contenedor:hover {
    transform: scale(1.005);
    
}

@media only screen and (max-width: 768px) {
    .servicios-contenedor {
        display: flex;
        max-width: 40vw;   
        align-items: center;  
        justify-content: center;         
        margin:0;
        
        /* margin: 10px 0 20px 20px; */
        
    }
    .servicios-titulo {
        font-size: 1.1rem;
    }

}

