.contacto-contenedor {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.contacto-iconos {
    display: flex;
    gap: 10px;
    font-size: 40px;
    color: #BABABA !important;
    margin:20px 0;
    
}

a {
    color: #BABABA;
}

a:visited {
    color:#BABABA ;
    text-decoration: none;
}



.icono:hover {
    color: white;
    transform: scale(1.05)
}

.icono1 {
    margin: 0;
}

@media only screen and (max-width: 768px) {
.contacto-iconos {
    display: flex;
    gap: 10px;
    font-size: 40px;
    color: #BABABA !important;
    margin:20px 0;
}

a:visited {
    color:#BABABA ;
    text-decoration: none;
}

}